/*
Theme Name: ST
Theme URI: https://wordpress.org/themes/customtheme
Author: Stephanie Too
Author URI: https://stephanietoo.com
Description: ST Purple Theme
Version: 1.0
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Text Domain: st
Tags: two-columns, multilevel-drop-down-menu, flexible-header, custom-colors, custom-header, custom-menu, custom-logo, editor-style, featured-images, post-formats, theme-options, threaded-commentsSome words you may want to say.
*/

/* * {
  -webkit-touch-callout: none; /* iOS Safari
  -webkit-user-select: none; /* Safari
  -khtml-user-select: none; /* Konqueror HTML
  -moz-user-select: none; /* Old versions of Firefox
  -ms-user-select: none; /* Internet Explorer/Edge
   user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox
} */

* {
  font-family: 'Griffy', cursive, DFKai-SB, BiauKai, 'AR PL UKai TW', Serif;
  box-sizing: border-box;
  accent-color: hotpink;
  max-width: 100%;
}

h1 {
  font-family: 'Princess Sofia', cursive, DFKai-SB, BiauKai, 'AR PL UKai TW', Serif;
  color: #ffe073;
}

h1:before {
  content: '꧁ ';
  color: #ffe073;
  white-space: nowrap;
}

h1:after {
  content: ' ꧂';
  color: #ffe073;
  white-space: nowrap;
}

@media (min-width: 801px) {

  h1:before {
    position: relative;
    top: -18px;
  }
  
  h1:after {
    position: relative;
    top: -18px;
  }

}

h2 {
  font-family: 'Princess Sofia', cursive, DFKai-SB, BiauKai, 'AR PL UKai TW', Serif;
  color: cyan;
}

h3 {
  font-family: 'Princess Sofia', cursive, DFKai-SB, BiauKai, 'AR PL UKai TW', Serif;
  color: deeppink;
}

h4 {
  font-family: 'Tangerine', cursive, DFKai-SB, BiauKai, 'AR PL UKai TW', Serif;
  color: #ffe073;
  font-size: 48px;
  margin: 0px;
  line-height: 1.2;
}

h5 {
  font-family: 'Tangerine', cursive, DFKai-SB, BiauKai, 'AR PL UKai TW', Serif;
  color: cyan;
  font-size: 36px;
  margin: 0px;
  line-height: 1.2;
}

h6 {
  font-family: 'Tangerine', cursive, DFKai-SB, BiauKai, 'AR PL UKai TW', Serif;
  color: deeppink;
  font-size: 24px;
  margin: 0px;
  line-height: 1.2;
}

.tangerine {
  font-family: "Tangerine", cursive, DFKai-SB, BiauKai, 'AR PL UKai TW', Serif;
  font-size: 22px;
  margin: 0px;
  line-height: 1;
}

.princess-sofia {
  font-family: 'Princess Sofia', cursive, DFKai-SB, BiauKai, 'AR PL UKai TW', Serif;
}

img {
  max-height: 100vh;
  vertical-align: middle;
}

iframe {
  resize: both;
  overflow: auto;
  max-width: 100%;
}

body {
  font-size: 110%;
  background-image: linear-gradient(to right, #606, deeppink, #606);
  color: yellow;
  margin: 0px;
  display: grid;
  grid-template-areas:
  'header header'
  'nav nav'
  'page page'
  'side main'
  'footer footer';
  grid-template-columns: 20% auto;
  grid-template-rows: max-content max-content auto max-content max-content;
}

header {
  grid-area: header;
  text-align: center;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
}

header ~ header {
  margin: 8px;
}

header h1 {
  margin-bottom: 0px;
}

header h3 {
  margin-top: 10px;
  margin-bottom: 30px;
}

nav {
  grid-area: nav;
  word-break: keep-all;
  text-align: center;
}

nav ~ nav,
footer ~ footer {
  margin: 3px;
}

section ~ section {
  margin-left: 3px;
  margin-right: 3px;
}

nav li a:link {
  text-decoration: none;
  color: yellow;
  min-width: 100px;
  padding: 8px;
  display:inline-block;
}

nav li a:visited {
  color: yellow;
}

nav li a:hover {
  background-image: linear-gradient(135deg, rgb(74, 234, 220) 0%, rgb(151, 120, 209) 20%, rgb(207, 42, 186) 40%, rgb(238, 44, 130) 60%, rgb(251, 105, 98) 80%, rgb(254, 248, 76) 100%);
  color: yellow;
}

nav li a:active,
nav li a:visited:active {
  color: white;
}

nav ul {
  display: inline;
  margin: 0;
  padding: 0;
}

nav ul li {
  display: inline-block;
}

nav li div {
  min-width: 100px;
  padding: 8px;
}

nav li li div {
  background: rgba(0, 0, 0, 0.5);
}

nav ul li.page_item_has_children:hover > div,
nav ul li.menu-item-has-children:hover > div,
nav ul li.page_item_has_children:hover a,
nav ul li.menu-item-has-children:hover a {
  background: rgba(255, 255, 255, 0.5);
  color: black;
}

nav ul li:hover > ul {
  display: block;
}

nav ul li ul {
  position: absolute;
  min-width: 100px;
  display: none;
  z-index: 3;
}

nav ul li.page_item_has_children:hover a:hover,
nav ul li.menu-item-has-children:hover a:hover {
  background-image: none;
}

nav ul li ul li.page_item_has_children:hover,
nav ul li ul li.menu-item-has-children:hover {
  display: inline-flex;
}

nav ul li ul li.page_item_has_children:hover > div,
nav ul li ul li.menu-item-has-children:hover > div {
  background: rgba(255, 255, 255, 0.5);
  color: black;
}

nav ul li ul li { 
  display: block;
}

nav ul li ul li a {
  display: block !important;
  color: black !important;
}

nav ul li ul li a:hover {
  background-image: linear-gradient(135deg, rgb(74, 234, 220) 0%, rgb(151, 120, 209) 20%, rgb(207, 42, 186) 40%, rgb(238, 44, 130) 60%, rgb(251, 105, 98) 80%, rgb(254, 248, 76) 100%) !important;
  color: yellow !important;
}

nav ul li ul li:hover > ul {
  display: block;
}

nav ul li ul li ul {
  position: absolute;
  left: 100%;
  min-width: 100px;
  display: none;
  z-index: 3;
}

body > div {
  grid-area: page;
  text-align: center;
  padding: 10px;
  background: rgba(0, 0, 0, 0.5);
}

main {
  grid-area: main;
  text-align: center;
  padding: 10px;
  background: rgba(0, 0, 0, 0.5);
}

section {
  grid-area: side;
  padding: 10px;
  background: rgba(0, 0, 0, 0.5);
}

footer {
  grid-area: footer;
  text-align: center;
  font-size: small;
  padding: 5px;
  background-color: black;
}

aside {
  margin: 5px;
  padding: 10px;
  line-height: 1.8;
}

article {
  text-align: left;
  margin: 5px;
  padding: 10px;
  line-height: 1.8;
}

.main {
  display: grid;
  grid-template-areas:
  'side1 main1';
  grid-template-columns: 20% auto;
}

.main > aside {
  grid-area: side1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
  
.main > aside ul,
.main > aside ol {
  padding-right: 10px;
}

.main > aside menu,
.main > aside dl {
  margin: 0px;
  padding-left: 0px;
}

.main > article {
  grid-area: main1;
}

hgroup {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

figure {
  margin: 1em;
  line-height: 1.8;
}

figure img {
  margin-top: 5px;
  margin-bottom: 10px;
}

figure aside {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
}

section h1,
article h1,
aside h1,
figure aside,
figure h1,
figure h2,
figure h3 {
  margin: 0px;
  padding: 0px;
}

figcaption {
  word-break: keep-all;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

figcaption div.item {
  line-height: 1;
}

figcaption span {
  margin-left: 5px;
  margin-right: 5px;
}

summary,
.pointer {
  cursor: pointer;
}

fieldset {
  border-color: lime;
  border-width: 1px;
  padding-top: 3px;
}

dialog {
  color: yellow;
  background: rgba(0, 0, 0, 0.5);
}

mark {
  background-color: pink;
}

hr {
  padding: 1px;
  border-style: none;
  background-image: linear-gradient(to right, #76581c, #d8eb1d, #76581c);
}

table {
  word-break: keep-all;
  background-image: linear-gradient(to right, #76581c, #d8eb1d, #76581c);
  border-color: hotpink;
  margin-left: auto;
  margin-right: auto;
}

caption {
  line-height: 1.8;
}

thead,
tfoot {
  background-color: black;
}

tbody {
  background-image: linear-gradient(to right, #606, deeppink, #606);
}

tr {
  background: rgba(0, 0, 0, 0.5);
}

th {
  color: cyan;
  padding: 5px;
}

td {
  text-align: center;
  padding: 5px;
}

#wp-calendar {
	width: 100%;
  background-image: linear-gradient(45deg, rgb(74, 234, 220) 0%, rgb(151, 120, 209) 20%, rgb(207, 42, 186) 40%, rgb(238, 44, 130) 60%, rgb(251, 105, 98) 80%, rgb(254, 248, 76) 100%);
}

#backToTop {
	position: fixed;
  bottom: 12px;
  right: 12px;
  padding: 0px 8px 0px 8px;
  z-index: 3;
}

#backToTop:hover,
#commentBtn:hover {
  color: blue;
	background-image: conic-gradient(green, blue, red, yellow, green);
}

div.btn1,
div.btn2 {
  padding: 5px 8px 3px 8px;
}

div.btn1 *,
div.btn2 * {
  margin: 0px;
}

.btn1 {
  color: yellow;
  cursor: pointer;
  border-style: none;
  background-image: none;
}

.btn1:hover,
button:hover,
input[type=button]:hover,
input[type=submit]:hover,
input[type=reset]:hover {
  color: deeppink;
  background-image: none;
  background-color: black;
}

.btn1:active,
.btn2:active {
  border-style: none;
}

.btn2 {
  color: yellow;
  cursor: pointer;
  border-style: none;
  background: rgba(0, 0, 0, 0.5);
}

.btn2:hover {
  color: black;
  background: rgba(255, 255, 255, 0.5);
}

button {
  color: yellow;
  background-image: linear-gradient(to right, #606, deeppink, #606);
  cursor: pointer;
  border-style: solid;
  border-color: #d8eb1d #76581c #76581c #d8eb1d;
  padding: 3px 8px 3px 8px;
}

input[type=button], input[type=submit], input[type=reset] {
  color: yellow;
  cursor: pointer;
  border-style: solid;
  border-color: #d8eb1d #76581c #76581c #d8eb1d;
  background-image: linear-gradient(135deg, rgb(74, 234, 220) 0%, rgb(151, 120, 209) 20%, rgb(207, 42, 186) 40%, rgb(238, 44, 130) 60%, rgb(251, 105, 98) 80%, rgb(254, 248, 76) 100%);
  padding: 3px 8px 3px 8px;
}

input[type=file] {
  background-image: linear-gradient(to right, yellow, green, blue, indigo, purple, red, orange);
}

button:active,
input[type=button]:active,
input[type=submit]:active,
input[type=reset]:active {
  border-style: solid;
  border-color: #76581c #d8eb1d #d8eb1d #76581c;
}

select,
input {
  background: rgba(255, 255, 255, 0.5);
  border-width: 1px;
  border-color: cyan;
  padding: 3px;
}

textarea {
  background: rgba(255, 255, 255, 0.5);
  border-width: 1px;
  border-color: cyan;
  padding: 3px;
  height: auto;
  width: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid green;
  -webkit-text-fill-color: deeppink;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}

select:focus,
input:focus,
textarea:focus {
  outline: none;
  border-color: magenta;
}

option {
  background-color: black;
  color: yellow;
}

option:hover {
  background-color: hotpink;
  color: black;
}

/* removes default scroll when using this.size
select {
  overflow: hidden;
} */

select option:checked{
  box-shadow: 0 0 0px 500px green inset !important;
}

optgroup {
  background-color: black;
  color: deeppink;
}

input:out-of-range {
  border-color: lime;
}

::placeholder {
  color: white
}

.mobile,
.hide,
.format-status .post-title,
.format-aside .post-title {
	display: none;
}

.post-content {
  padding-bottom: 30px;
}

.main > article.post-content {
  padding-right: 18px;
}

.format-gallery .thumb-n {
  margin-top: 3px;
  margin-bottom: 3px;
}

.post-meta figure {
  margin-bottom: 0px;
}

figcaption.post-meta {
  flex-wrap: nowrap;
  justify-content: space-between;
}

figcaption.post-meta :nth-child(2) {
  white-space: nowrap;
}

blockquote:before {
	content: open-quote;
  color: deeppink;
	font-size: 140px;
	line-height: .9;
  margin: 0px -50px ;
	position: absolute;
}

q {
  color: skyblue;
}

q:before,
q:after {
  color: yellow;
}

.rb1 {
  background-image: linear-gradient(to right, purple, red, orange, yellow, green, blue, indigo);
}

.rb2 {
  background-image: linear-gradient(to right, yellow, green, blue, indigo, purple, red, orange);
}

.rb3 {
  background-image: conic-gradient(yellow, green, blue, purple, red, orange, yellow, green, blue, purple, red, orange, yellow);
}

.rb4 {
  background-image: conic-gradient(red, yellow, green, blue, red);
}

.colour-code-ref {
  background-image: linear-gradient(
  #fff
  ,#ccc
  ,#cff
  ,#fcf
  ,#ffc
  ,#fcc
  ,#cfc
  ,#ccf
  ,#0ff
  ,#0cc
  ,#0fc
  ,#0cf
  ,#ff0
  ,#cc0
  ,#fc0
  ,#cf0
  ,#f0f
  ,#c0c
  ,#f0c
  ,#c0f
  ,#f00
  ,#c00
  ,#0f0
  ,#0c0
  ,#00f
  ,#00c
  ,#000
  );}

a:link {
  color: lime;
  text-decoration: none;
}

a:visited {
  color: mediumspringgreen;
  text-decoration: none;
}

a:hover,
.hotpink {
  color: hotpink;
}

a:active,
a:visited:active,
::marker {
  color: deeppink;
}

.lime {
  color: lime;
}

.cyan {
  color: cyan;
}

.magenta {
  color: magenta;
}

.format-status .post-content,
.format-aside .post-content,
.pink {
  color: pink;
}

.skyblue {
  color: skyblue;
}

.red {
  color: red;
}

.black {
  background-color: black;
  background-image: none;
}

.nobg {
  background: none;
}

.b50 {
  background: rgba(0, 0, 0, 0.5);
}

.w50 {
  background: rgba(255, 255, 255, 0.5);
}

.border {
  background-image: linear-gradient(to right, #76581c, #d8eb1d, #76581c);
}

.background {
  background-image: linear-gradient(to right, #606, deeppink, #606);
}

.center {
  text-align: center;
}

.right {
  display: flex;
  flex: auto;
  justify-content: flex-end;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.baseline {
  align-items: baseline;
}

.jsb {
  justify-content: space-between;
}

.item {
  flex: none;
  width: -moz-fit-content;
  width: fit-content;
  height: fit-content;
  padding: 3px;
}
  
.round {
  border-radius: 50%;
}

.w100 {
  width: 100%;
}

.h100 {
  height: 100vh;
}

.blog {
  grid-template-rows: max-content max-content max-content auto max-content;
}

.sticky {
  position: sticky;
  bottom: 0px;
}

.scroll-x {
  overflow-x:auto;
}

.lh {
  line-height: 1.8;
}

.m0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.thumb-n {
  max-width: 320px;
  max-height: 320px;
}

.thumb {
  cursor: pointer;
  max-width: 240px;
  max-height: 240px;
}

.thumb-s {
  cursor: pointer;
  max-width: 100px;
  max-height: 100px;
}

.gem {
  min-height: 220px;
}

/* The modal's background */
.modal {
  display: none;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* The modal box */
.modal-cell {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.modal-cell * {
  margin: 0px;
  resize: unset;
}

.popup-wrapper {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.popup {
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin: 10% auto;
  position: relative;
}

.popup-close {
  position: absolute;
  right: 20px;
  top: 15px;
  font-weight: bold;
  cursor: pointer;
}


/* Responsive layout - makes a one column-layout instead of a two-column layout */

@media (max-width: 1470px) {

  body {
    font-size: 100%;
  }

}

@media (max-width: 1328px) {

  body,
  .main {
    grid-template-columns: 25% auto;
  }

  .avatar,
  .attachment-large,
  .size-large {
    height: auto;
    max-width: 100%;
  }

}

@media (max-width: 1024px) {

  nav ul li ul {
    background-image: linear-gradient(135deg, rgb(74, 234, 220) 0%, rgb(151, 120, 209) 20%, rgb(207, 42, 186) 40%, rgb(238, 44, 130) 60%, rgb(251, 105, 98) 80%, rgb(254, 248, 76) 100%);
  }

  #post-box h1:before {
    display: none;
  }
  
  #post-box h1:after {
    display: none;
  }

  body {
    grid-template-areas:
    'header'
    'nav'
    'page'
    'main'
    'side'
    'footer';
    grid-template-columns: auto;
    grid-template-rows: max-content max-content auto max-content max-content max-content;
  }

  .blog {
    grid-template-rows: max-content max-content max-content auto auto max-content;
  }

  header ~ header {
    margin: 5px;
  }

  nav ~ nav,
  footer ~ footer {
    margin: 2px;
  }

  section ~ section {
    margin-top: 2px;
    margin-left: 0px;
    margin-right: 0px;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  section ul,
  section ol {
    margin: 0px;
    padding-left: 15px;
  }

  section menu,
  section dl {
    margin: 0px;
    padding-left: 0px;
  }
  
  section dd {
    margin-left: 20px;
  }

  .desktop{
    display: none;
  }

  .mobile{
    display: block;
  }
  
  .wrap,
  .right {
    flex-wrap: wrap;
  }

  figure div.item {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  figure img,
  figure button {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  figure .item button {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .thumb-n {
    max-width: 240px;
    max-height: 240px;
  }

  .thumb {
    max-width: 200px;
    max-height: 200px;
  }

}

@media (max-width: 480px) {

  header ~ header {
    margin: 3px;
  }

  nav ~ nav,
  footer ~ footer {
    margin: 1px;
  }

  section ~ section {
    margin-top: 1px;
  }

  nav ul li ul li.page_item_has_children:hover,
  nav ul li ul li.menu-item-has-children:hover {
    display: block;
  }
  
  nav ul li ul li ul {
    position: relative;
    left: auto;
  }

  .main {
    grid-template-areas:
    'side1'
    'main1';
    grid-template-columns: auto;
  }

  .main > aside {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  .main > aside ul,
  .main > aside ol {
    margin: 0px;
    padding-left: 15px;
    padding-right: 0px;
  }
  
  .main > aside dd {
    margin-left: 20px;
  }

  #backToTop {
  bottom: 55px;
  }

  table {
    border-spacing: 1px;
  }

  .post-title,
  .post-content-a {
    text-align: center;
  }

  .post-meta figure {
    margin: 0px;
  }

  .main > article.post-content {
    padding-left: 18px;
  }

  figcaption.post-meta :nth-child(2) {
    white-space: normal;
  }

  figcaption.post-meta {
    text-align: center;
    flex-direction: column;
  }
  
  .jsb {
  justify-content: space-around;
  }

  .gem {
    min-height: auto;
    max-width: 100%;
  }

  .thumb-n {
    max-width: 200px;
    max-height: 200px;
  }

}

@media (max-height: 633px) {

.sticky {
  position: unset;
}

}

@media (max-width: 346px) {

  h1 {
    margin-bottom: 0.67em;
  }

  h1:before {
    display: none;
  }
  
  h1:after {
    display: none;
  }

}

@media (max-width: 240px) {

  img,
  .thumb,
  .thumb-n,
  .thumb-s {
    max-width: 100%;
  }
  
  header h3 {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  figure aside {
    grid-template-columns: auto;
  }

  figcaption span {
    margin-left: auto;
    margin-right: auto;
  }

  nav li {
    display: block;
    width: 100%;
  }

  nav li a:link,
  nav li div,
  nav ul li ul,
  nav ul li ul li ul {
    min-width: auto;
    width: 100%;
  }
  
  nav ul li ul,
  nav ul li ul li ul {
    position: unset;
    background-image: none;
  }

  * {
    word-break: break-all;
  }

}